import React from 'react';
import CatoImg from '../cato-image';
import './style.scss';
import Lazyload from'react-lazyload'

const VerticalChallengeSolution = ({rows, isMobile}) => {
    return (
        <div className="vertical-challenge-solution">
            {rows.map((row, i) => row.map((type, j) => (
                <div key={`vertical-challenge-row-${i}-${j}`} className={`row ${j % 2 === 1 ? 'solution' : 'challenge'}`}>
                    <div className="label">
                        <h3>{type.label}</h3>
                    </div>
                    <div className="space"/>
                    <div className="text-wrapper">
                        {type.heading && <h3 className="heading" dangerouslySetInnerHTML={{__html: type.heading}}/>}
                        {isMobile && type.img &&
                        <div className="img-wrapper">
                            <Lazyload height={300} >
                                <CatoImg img={type.img} loading={'lazy'} width={530} height={300}/>
                            </Lazyload>
                        </div>
                        }
                        {type.text && <div className="text" dangerouslySetInnerHTML={{__html: type.text}}/>}
                    </div>
                    <div className="space"/>
                    {!isMobile && type.img &&
                    <div className="img-wrapper">
                        <Lazyload height={300} >
                            <CatoImg img={type.img} loading={'lazy'} width={530} height={300}/>
                        </Lazyload>
                    </div>
                    }
                </div>
            )))}
        </div>
    )
};

export default VerticalChallengeSolution;