import React from 'react';
import LinkColumns from '../link-columns';
import ResourcesFooterShortText from '../resources-footer-short-text';
import './style.scss';

const RelatedContentStrip = ({links,  resources, headline}) => {

    const linksData = links.length ? links : null;

    return (
        <section className="related-content">
            {links &&
                <LinkColumns
                    heading={headline}
                    items={linksData}
                />
            }
            {resources &&
            <ResourcesFooterShortText
                className="small-white"
                data={resources}
                useSection={false}
                showDesc={false}
                mobileCarousel={true}
            />
            }
        </section>
    )
};

export default RelatedContentStrip;