import React, {useEffect, useState} from 'react';
import CatoImg from '../cato-image';
import CatoSlider from '../slider';
import {IS_MOBILE} from "../../common/consts";
import './style.scss';

const ExternalResource = ({title, desc, img, link}) => {
    return (
        <div className={'resource'}>
            <a href={link || ""} target="_blank" rel="noopener noreferrer">
                <div className="img-wrapper">
                <CatoImg img={img} alt={"resource"}/>
                </div>
                <h4>{title}</h4>
                <span>{desc}</span>
            </a>
        </div>
    )
}

const ResourcesFooterShortText = (props) => {

    const [isMobile, setMobile] = useState(false);

    useEffect(() => {
        if(IS_MOBILE) {
            setMobile(true);
        }
    }, []);


    const resources = props.data.map((item, i) => (
        <ExternalResource
            key={`external-resource-${i}`}
            title={item.resourceFields.resourceType}
            desc={props.showDesc ? item.resourceFields.resourceShortDescription : item.title}
            img={item.resourceFields.resourceSmallImage ? item.resourceFields.resourceSmallImage.localFile : null}
            link={item.resourceFields.resourceLink}
        />
    ))

    const useSlider = props.mobileCarousel && isMobile;

    const resourcesWrapper = useSlider
    ?
        <CatoSlider
            variableWidth={true}
            dots={false}
            centerMode={true}
            slidesToShow={1}
            centerPadding={0}
            slidesToScroll={1}
            arrows={false}
        >
            {resources}
        </CatoSlider>
        :
        resources

    return (
        props.useSection ?
        <section className={`section-external-resources ${props.className} ${useSlider ? 'slider' : ''}`}>
            <div className={'restrict-1200'}>
                {resourcesWrapper}
            </div>
        </section>
            :
            <div className={`section-external-resources ${props.className} ${useSlider ? 'slider' : ''}`}>
                <div className={'restrict-1200'}>
                    {resourcesWrapper}
                </div>
            </div>
    )
}

ResourcesFooterShortText.defaultProps = {
    showDesc: true,
    useSection: true,
    mobileCarousel: false
}

export default ResourcesFooterShortText;