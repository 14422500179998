import React from 'react';
import CatoImg from '../cato-image';
import './style.scss';

const BottomAlignQuote = ({quote, customerImg, customerName, customerPosition}) => {
    // console.log(quote);
    return (
        <div className="bottom-align-quote">
            <div className="quote" dangerouslySetInnerHTML={{__html: quote}}/>
            <div className="name-tag">
                {customerImg &&
                    <div className="img-wrapper">
                        <CatoImg img={customerImg}/>
                    </div>
                }
                <div className="name-wrapper">
                    <div className="name">{customerName},</div>
                    <div className="position">{customerPosition}</div>
                </div>
            </div>
        </div>
    )
}

export default BottomAlignQuote;