import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {IS_MOBILE} from "../common/consts";
import Layout from '../components/layout';
import MidPageInfoBanner from '../components/mid-page-info-banner';
import ContentBreadCrumbs from '../components/bread-crumbs';
import VerticalChallengeSolution from '../components/vertical-challenge-solution';
import ComparableTableSection from '../components/comparable-table-section';
import FAQ from '../components/faq';
import {videoSectionMapper, resourcesTrioMapper, mapStickyBanner, mapGartner} from '../common/utils';
import CheckTable from '../components/check-table';
import StickyBannerCTA from '../components/sticky-banner-cta';
import RelatedContentStrip from '../components/related-content-strip';
import '../assets/styles/pages/glossary-page.scss';
import '../assets/styles/responsive/pages/glossary-mobile.scss';

const glossaryBannerSettings = {
    // withCarousel: false,
    isTopSection: true,
    className: 'bold-heading heading-75 text-20 img-top-align mobile-center wide-image short-text',
    includeSideOnMobile: true
}

const secondRowSettings = {
    imageFirst: true,
    withCarousel: false,
    includeSideOnMobile: true,
    className: 'heading-42 heading-weight-600 text-20 text-align-top mobile-center short-text glossary'
};

const carouselDataMap = (items) => items.map((resource) => ({
    link: resource.resourceFields.resourceLink || "",
    img: resource.resourceFields.resourceSmallImage ? resource.resourceFields.resourceSmallImage : null,
    bold: resource.resourceFields.resourceType || "",
    light: resource.title || ""
}))

const GlossaryPage = (props) => {

    const [isMobile, setMobile] = useState(false);

    useEffect(() => {
        if(IS_MOBILE) {
            setMobile(true);
        }
    });

    const {data} = props;
    const {wpPage} = data;
    const {glossaryBanner, glossaryInfoStripParent, challengeSolutionsRowsParent, relatedContentLinksParent, comparableTableSection, saseTableTexts, resourcesTrioFooter, videoSection, FAQSection} = wpPage;

    const carouselData = glossaryBanner.glossaryBannerResources && glossaryBanner.glossaryBannerResources.length > 0 ? carouselDataMap(glossaryBanner.glossaryBannerResources) : null;

    const youtube = videoSection && videoSection.videos ? videoSectionMapper(videoSection.videoSectionHeadline, videoSection.videos) : null;

    const coolInsights = resourcesTrioFooter && resourcesTrioFooter.resourcesTrioResources ? resourcesTrioMapper( resourcesTrioFooter.resourcesTrioHeadline, resourcesTrioFooter.resourcesTrioResources) : null;

    const stickyBanner = mapStickyBanner(wpPage);
    const gartner = mapGartner(wpPage);

    const relatedContentLinks = relatedContentLinksParent && relatedContentLinksParent.relatedContentLinksGroup  && relatedContentLinksParent.relatedContentLinksGroup.links ? relatedContentLinksParent.relatedContentLinksGroup.links : null;

    const relatedContentResources = relatedContentLinksParent && relatedContentLinksParent.relatedContentLinksGroup && relatedContentLinksParent.relatedContentLinksGroup.resourcesTrioResources
    ? relatedContentLinksParent.relatedContentLinksGroup.resourcesTrioResources :  null;

    return (
        <Layout className="glossary-page"
                lang={props.pageContext.lang}
                whiteMenu={true}
                breadcrumbs={[{title: wpPage.title, path: props.location.pathname}]}
                yoastData={wpPage.seo || null}
                seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
                title={wpPage.title}
                gartner={gartner}
                seoPath={props.location.pathname}
                translations={props.pageContext.translations}
                wpid={wpPage.databaseId}
                {...{youtube}}
                {...{coolInsights}}
        >
            {!isMobile &&
            <ContentBreadCrumbs links={[{path: props.location.pathname, title: wpPage.title}]}/>
            }
            <section className="banner">
                <MidPageInfoBanner
                    alignImage={true}
                    bannerImg={glossaryBanner.glossaryImage}
                    heading={glossaryBanner.glossaryHeadline || ''}
                    text={glossaryBanner.glossaryText || ""}
                    withCarousel={carouselData !== null}
                    carouselData={carouselData}
                    {...glossaryBannerSettings}
                />
            </section>
            {
                (stickyBanner) &&
                <StickyBannerCTA {...{stickyBanner}}/>
            }
            {glossaryInfoStripParent && glossaryInfoStripParent.glossaryInfoStrip && glossaryInfoStripParent.glossaryInfoStrip.length &&
                <section className="second-banner" style={{backgroundColor: "#fafafa"}}>
                    {
                        glossaryInfoStripParent.glossaryInfoStrip.map((strip, i) =>
                            <MidPageInfoBanner
                                key={`glossary-info-${i}`}
                                bannerImg={strip.image && strip.showImageOrVideo !== 'video' ? strip.image : null}
                                bannerVid={strip.videoCode && strip.showImageOrVideo !== 'image' ? strip.videoCode : null}
                                heading={strip.headline || ''}
                                text={strip.text || ''}
                                bottomQuote={strip.bottomQuote ? {
                                    quote: strip.bottomQuote.quote || "",
                                    customerImg: strip.bottomQuote.customerImage ? strip.bottomQuote.customerImage : null,
                                    customerName: strip.bottomQuote.customerName || "",
                                    customerPosition: strip.bottomQuote.customerPosition || ""
                                } : null}
                                {...secondRowSettings}
                            />
                        )
                    }
                </section>
            }
            {challengeSolutionsRowsParent.challengeSolutionsRows && challengeSolutionsRowsParent.challengeSolutionsRows.length &&
            <section className="challenge-solution-section">
                <VerticalChallengeSolution isMobile={isMobile} rows={challengeSolutionsRowsParent.challengeSolutionsRows.map(row => ([{
                    label: row.challengeLabel,
                    heading: row.challengeHeading,
                    img: row.challengeImage
                        ?
                        row.challengeImage
                        :
                        null,
                    text: row.challengeText

                },{label: row.solutionLabel,
                    heading: row.solutionHeading,
                    img:
                        row.solutionImage
                            ?
                            row.solutionImage
                            :
                            null,
                    text: row.solutionText}]))}/>
            </section>
            }
            {
                relatedContentLinks &&
                    <RelatedContentStrip
                        headline={relatedContentLinksParent.relatedContentLinksGroup.relatedContentHeadline || "Related Content"}
                        links={relatedContentLinks.map(item => ({
                            path: item.link,
                            label: item.linkDisplayName
                        }))}
                        resources={relatedContentResources}
                    />
            }
            {comparableTableSection && comparableTableSection.compTableRows && comparableTableSection.compTableRows.length &&
                <ComparableTableSection
                    headline={comparableTableSection.compTableHeadline || ""}
                    columns={[comparableTableSection.compTableLeftColumnTitle, comparableTableSection.compTableRightColumnTitle]}
                    rows={comparableTableSection.compTableRows.map(row => ({
                        label: row.compTableRowLabel,
                        columns: row.columns.map(column => ({
                            bold: column.compBoldText,
                            text: column.lightText
                        }))
                    }))}
                    isMobile={isMobile}
                />
            }
            {saseTableTexts && saseTableTexts.saseInclude && saseTableTexts.saseHeadline && saseTableTexts.saseText &&
                <CheckTable
                    headline={saseTableTexts.saseHeadline || null}
                    text={saseTableTexts.saseText || null}
                    afterTable={saseTableTexts.saseTextAfterTable || null}
                    lang={props.pageContext.lang}
                />
            }
            {FAQSection.faq && FAQSection.faq.length &&
                <FAQ
                    headline={FAQSection.faqHeadline}
                    questions={ FAQSection.faq.map(q => ({
                        question: q.faqQuestion,
                        answer: q.faqAnswer
                    }))
                    }
                    button={ FAQSection.faqButton ? {
                        label: FAQSection.faqButton.buttonLabel && FAQSection.faqButton.buttonLabel.length > 0 ? FAQSection.faqButton.buttonLabel : null,
                        path: FAQSection.faqButton.buttonLink && FAQSection.faqButton.buttonLink.length > 0 ? FAQSection.faqButton.buttonLink : null
                    } : null}
                />
            }
        </Layout>
    )
}

export default GlossaryPage;

export const query = graphql`
    query glossaryPageQuery($id: String!){
        wpPage(id: {eq: $id}){
             ...YoastData
            title
            content
            slug
            id
            databaseId
            date(formatString: "MMMM DD, YYYY")
            path: uri
            parent {
                ... on WpPage {
                    id
                    title
                    path: uri
                }
            }
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
            glossaryBanner {
                glossaryHeadline
                glossaryText
                glossaryImage {
                    altText
                    localFile {
                        publicURL
                    }
                }
                glossaryBannerResources {
                    ... on WpResource {
                        id
                        title
                        resourceFields {
                            resourceLink
                            resourceType
                            resourceShortDescription
                            resourceSmallImage {
                                altText
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 242){
                                            ...GatsbyImageSharpFluid_withWebp_noBase64
                                        }
                                    }
                                    extension
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
            glossaryInfoStripParent {
                 glossaryInfoStrip {
                    headline
                    text
                    showImageOrVideo
                    image {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                    videoCode
                    bottomQuote {
                        quote
                        customerImage {
                            altText
                            localFile {
                                publicURL
                            }
                        }
                        customerName
                        customerPosition
                    }
                }
            }
            relatedContentLinksParent {
                relatedContentLinksGroup {
                    relatedContentHeadline
                    links {
                        link
                        linkDisplayName
                    }
                    resourcesTrioResources {
                        ... on WpResource {
                            id
                            title
                            resourceFields {
                                resourceType
                                resourceShortDescription
                                resourceSmallImage {
                                    altText
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 220, quality: 100){
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                        }
                                        publicURL
                                    }
                                }
                                resourceButtonText
                                resourceLink
                            }
                        }
                    }
                }
            }
            challengeSolutionsRowsParent {
                challengeSolutionsRows {
                    challengeLabel
                    challengeHeading
                    challengeText
                    challengeImage {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                    solutionLabel
                    solutionHeading
                    solutionText
                    solutionImage {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                }
            }
            comparableTableSection {
                compTableHeadline
                compTableLeftColumnTitle
                compTableRightColumnTitle
                compTableRows {
                    compTableRowLabel
                    columns {
                        compBoldText
                        lightText
                    }
                }
            }
            saseTableTexts {
                saseInclude
                saseHeadline
                saseText
                saseTextAfterTable
            }
            tryUsStrip {
                tryUsHeadingText
                tryUsButtonText
            }
            ...StickyBanner
            ...VideoSection
            ...ResourcesTrio
            ...FAQSection
            ...GartnerStrip
        }
    }
`
