import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';


const ComparableTableSection  = ({headline, rows, columns, isMobile}) => {
    return (
        <section className="comparable-table-section">
            <h2>{headline}</h2>
            {!isMobile
                ?
                <div className="comparable-table">
                    <table>
                        <thead>
                        <tr className="heading">
                            <th/>
                            {columns.map((column, i) => <th key={`column-${i}`}><h2>{column}</h2></th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {rows.map((row, i) =>
                            <tr key={`row-${i}`}>
                                <td className="label"><h3>{row.label}</h3></td>
                                {row.columns.map((column, j) =>
                                    <td key={`td-${i}-${j}`}>
                                        {column.bold && <h3>{column.bold}</h3>}
                                        {column.text && <div dangerouslySetInnerHTML={{__html: column.text}}/>}
                                    </td>
                                )}
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                    :
                <div className="comparable-table">
                    {
                        rows.map((row, i) =>
                            <div className="table-row" key={`mobile-table-row-${i}`}>
                                <div className="subject"><h3>{row.label}</h3></div>
                                {row.columns.map((col, j) =>
                                    <div className="row">
                                        <div className="label">
                                            <h2>
                                                {columns[j]}
                                            </h2>
                                        </div>
                                        <div className="text">
                                            <h3>{col.bold}</h3>
                                            <div dangerouslySetInnerHTML={{__html: col.text}}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    }
                </div>
            }
        </section>
    )
};

ComparableTableSection.propTypes = {
    headline: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.string),
    rows: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        columns: PropTypes.arrayOf(PropTypes.shape({
            bold: PropTypes.string,
            text: PropTypes.string
        }))
    })),
    isMobile: PropTypes.bool.isRequired
}

export default ComparableTableSection;