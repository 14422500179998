import React from 'react';
import {LANG} from '../../common/consts'
import './style.scss';


const CheckTable = ({headline, text, afterTable, lang}) => {

    const TABLE_MAP = {
        columnTitles: {
            [LANG.ENG]: [<th></th>,
                        <th>Replace MPLS /<br/>Increase BW</th>,
                        <th>Global<br/>Connectivity</th>,
                        <th>Secure<br/>DIA</th>,
                        <th>Optimize <br/>Cloud Access</th>,
                        <th>Optimize <br/>Mobile Access</th>,
                        <th>Really Simple <br/>Management</th>
            ],
            [LANG.FR]: [<th></th>,
                <th>Remplacement MPLS /<br/>Augmentation Bande passante</th>,
                <th>Connectivité<br/>globale</th>,
                <th>Accès internet local</th>,
                <th>Optimisation Accès Cloud</th>,
                <th>Optimisation Utilisateurs Mobiles</th>,
                <th>Console unique <br/>et simplifiée de management</th>
            ],
            [LANG.DE]: [<th></th>,
                <th>MPLS ersetzen/<br/>Bandbreite erhöhen</th>,
                <th>Globale <br/>Vernetzung</th>,
                <th>Sicherer direkter Internetzugang</th>,
                <th>Optimierter Cloudzugriff</th>,
                <th>Optimierter Mobilzugriff</th>,
                <th>Einfachste  <br/>Verwaltung</th>
            ]
        },
        rowTitles: {
            [LANG.ENG]: [<td>SASE</td>,<td>Edge SD-WAN</td>,<td>Private Global Backbone</td>,<td>NGFW / UTM</td>,<td>SWGs</td>],
            [LANG.FR]: [<td>SASE</td>,<td>Edge SD-WAN</td>,<td>Backbone mondial privé</td>,<td>NGFW / UTM</td>,<td>SWGs</td>],
            [LANG.DE]: [<td>SASE</td>,<td>Edge SD-WAN</td>,<td>Privates globales Backbone-Netzwerk</td>,<td>NGFW / UTM</td>,<td>SWGs</td>]
        }
    }

    const columnTitles = TABLE_MAP.columnTitles[lang] ? TABLE_MAP.columnTitles[lang] : TABLE_MAP.columnTitles[LANG.ENG];

    const rowTitles = TABLE_MAP.rowTitles[lang] ? TABLE_MAP.rowTitles[lang] : TABLE_MAP.rowTitles[LANG.ENG];

    return (
        <section className="check-table-section">
            <div className="text-wrapper">
                {headline && <h1>{headline}</h1>}
                { text &&
                    <div dangerouslySetInnerHTML={{__html: text}}/>
                }
            </div>
            <div className="table">
                <table>
                    <thead>
                        <tr>
                            {[...columnTitles]}
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {rowTitles[0]}
                        <td className="check"/>
                        <td className="check"/>
                        <td className="check"/>
                        <td className="check"/>
                        <td className="check"/>
                        <td className="check"/>
                    </tr>
                    <tr>
                        {rowTitles[1]}
                        <td className="check"/>
                        <td className="cross"/>
                        <td className="cross"/>
                        <td className="half"/>
                        <td className="cross"/>
                        <td className="cross"/>
                    </tr>
                    <tr>
                        {rowTitles[2]}
                        <td className="cross"/>
                        <td className="check"/>
                        <td className="cross"/>
                        <td className="check"/>
                        <td className="cross"/>
                        <td className="cross"/>
                    </tr>
                    <tr>
                        {rowTitles[3]}
                        <td className="check"/>
                        <td className="cross"/>
                        <td className="check"/>
                        <td className="cross"/>
                        <td className="half"/>
                        <td className="cross"/>
                    </tr>
                    <tr>
                        {rowTitles[4]}
                        <td className="cross"/>
                        <td className="cross"/>
                        <td className="check"/>
                        <td className="cross"/>
                        <td className="check"/>
                        <td className="cross"/>
                    </tr>
                    </tbody>
                    
                </table>
                {afterTable && <div className="last-row" dangerouslySetInnerHTML={{__html: afterTable}}/>}
            </div>
        </section>
    )
}

export default CheckTable;