import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import {Link} from 'gatsby';
import CatoImg from '../cato-image';
import {IS_MOBILE, IS_TABLET} from "../../common/consts";
import './style.scss';

class ResourcesSideCarousel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isMobile: false,
            isTablet: false
        }
    }

    componentDidMount() {
        if(IS_MOBILE) {
            this.setState({...this.state, isMobile: true})
        } else if (IS_TABLET){
            this.setState({...this.state, isTablet: true})
        }
    }

    generateSlideContent = (item) => {

        if(typeof window === 'undefined') {
            return
        }

        const klass = 'resources-slide-inner';

        const image = item.img ?
            <div className="img-wrapper">
                <CatoImg img={item.img} style={{maxWidth: '100%', maxHeight: '100%'}} width={242} height={154} loading={'lazy'} fadeIn={false}/>
            </div>
            :
            <div className="img-wrapper" />;

        const text = <div className="text-wrapper"><h3>{item.bold}</h3><p>{item.light}</p></div>;
        const linkHost = item.link && item.link[0] !== '/' ? item.link.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i) : false;
        const extLink = linkHost ? linkHost !== window.location.host : false;



        return item.link
            ? extLink
                ?
                <a href={item.link} className={klass} target="_blank" rel="noopener noreferrer">{image}{text}</a>
                :
                <Link to={item.link} className={klass}>{image}{text}</Link>
            :
            <div className={klass}>{image}{text}</div>;
    };

    generateSlides = () => {
      const {data} = this.props;
      return data.map((item,i) => {
          return (
              <div className="resources-side-carousel-slide" key={`resources-slide-${i}`}>
                  {this.generateSlideContent(item)}
              </div>
          )
      })

    };

    handleNext = () => this.slider.slickNext()

    render() {

        const slides = this.generateSlides();

        const slideSize = this.state.isMobile
            ?
            typeof window !== 'undefined'
                ?
                document.documentElement.clientWidth * 0.37
                :
                140
            :
            this.state.isTablet
                ?
                160
                :
                282;

        return (
            <div className={`resources-side-carousel-slider-wrapper${slides.length <= 2 ? ' no-next' : ''}`} style={{width: `calc((${slideSize}px * ${this.props.slidesToShow}))`}}>
                <Slider ref={slider => (this.slider = slider)} {...this.props} className="resources-side-carousel-slider">
                    {slides}
                </Slider>
                <div onClick={this.handleNext} className="resources-slider-next"/>
            </div>
        )
    }
}

ResourcesSideCarousel.propTypes = {
    dots: PropTypes.bool,
    infinite: PropTypes.bool,
    speed: PropTypes.number,
    slidesToShow: PropTypes.number,
    slidesToScroll: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object)
};

ResourcesSideCarousel.defaultProps = {
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: false
};

export default ResourcesSideCarousel;