import React from 'react';
import {Link} from 'gatsby';
import './style.scss';

const ContentBreadCrumbs = ({parents, showCurrent, title, links, style}) => {

    // const links =

    return (
        <div className="bread-crumbs-wrapper" style={style}>
            <div className="bread-crumbs">
                <Link to="/">Home</Link>
                {links &&
                    links.map((link, i) => (
                        <>
                        <span> › </span>
                        {
                            i === links.length - 1 ?
                                <span>{link.title}</span>
                                :
                            <Link to={link.path}>{link.title}</Link>
                        }
                        </>
                    ))
                }
            </div>
        </div>
    )
};

export default ContentBreadCrumbs;