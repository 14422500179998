import React from 'react';
import PropTypes from 'prop-types';
import CatoLink from '../cato-link';
import './style.scss';

const LinkColumns = ({heading, items}) => {
    return (
        <div className="link-columns-wrapper">
            {heading && <h2>{heading}</h2>}
            <ul className="link-columns">
                {items.map((item, i) => (
                    <li key={`column-link-${i}`}>
                        <CatoLink
                            path={item.path}
                        >
                            <span>{item.label}</span>
                        </CatoLink>
                    </li>
                ))}
            </ul>
        </div>
    )
}

LinkColumns.propTypes = {
    heading: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string,
        label: PropTypes.string
    })).isRequired
}

export default LinkColumns;