import React, {Component, Suspense} from 'react';
import PropTypes from 'prop-types';
import CatoImg from '../cato-image';
import CustomerSideQuote from '../customer-side-quote';
import ResourcesSideCarousel from '../resources-side-carousel';
import {IS_MOBILE} from "../../common/consts";
import './style.scss';
import CatoButton from "../cato-button/index";
import CatoLink from '../cato-link';
import {StaticQuery, graphql} from 'gatsby';
import BottomAlignQuote from '../bottom-align-quote';
import Youtube from 'react-youtube';
import CatoYoutube from '../cato-youtube';

class MidPageInfoBanner extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isMobile: false
        }
    }

    componentDidMount() {
        if(IS_MOBILE) {
            this.setState({isMobile: true})
        }
    }

    generateQuotationMarks = () => (
        <StaticQuery
            query={graphql`
            query {
            quotes: file(relativePath: { eq: "solutions/quotation-marks.png"}) {
                childImageSharp {
                    fluid(maxWidth: 220) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
            quotesMobile: file(relativePath: { eq: "solutions/quotation_marks_cropped.png"}) {
                childImageSharp {
                    fluid(maxWidth: 80) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }`}
            render={data => <CatoImg img={!this.state.isMobile ? data.quotes : data.quotesMobile}/>}
        />
    )

    generateText = () => {
        const {heading, text, withCarousel, link, alignImage, isTopSection, smallerTopText, smallerGreenText, bottomQuote } = this.props;
        const {isMobile} = this.state;

        const carousel = withCarousel && this.props.carouselData !== undefined ? <ResourcesSideCarousel slidesToShow={2} data={this.props.carouselData}/> : null;
        // const preLink = `<a href="${link}" class="read-more-arrow">`;
        // const postLink = `</a>`;

        const headingTag = isTopSection ? <h1 dangerouslySetInnerHTML={{__html: heading}}/> : <h2 dangerouslySetInnerHTML={{__html: heading}}/>

        const bottomAlignQuote = bottomQuote ? <BottomAlignQuote {...bottomQuote}/> : null;

        // const textForDisplay = link && !isMobile ? preLink + text + postLink : text;
        return ( <div className={`text-wrapper${smallerTopText ? ' small-heading' : ''}`}>
            {(!alignImage || isMobile) && headingTag}
            {smallerGreenText && <h2 className="smaller-green">{smallerGreenText}</h2>}
            <div className="text" dangerouslySetInnerHTML={{__html: text}}/>
            {link &&
            <CatoLink
                className="cato-button read-more"
                path={link}
            >Read more</CatoLink>
            }
            {carousel}
            {bottomAlignQuote}
        </div>);
    };

    generateSideContent = () => {
        const {bannerImg, sideQuote, bannerVid } = this.props;
        // const {isMobile} = this.state;
        if (!sideQuote) {

            const img = bannerImg ? <CatoImg img={bannerImg} width={228} height={150}/> : bannerVid ? <CatoYoutube videoId={bannerVid} autoplay={false}/>: null;
            return <div className="image-wrapper">{img}</div>

        } else if (sideQuote) {
            // const img = sideQuote.logo ? <CatoImg img={sideQuote.logo} style={{maxWidth: '200px', marginLeft: '50px'}}/> : this.generateQuotationMarks();
            // const customerImg = !isMobile ? sideQuote.customerImg : sideQuote.customerImgMobile;
            // const customerImg = sideQuote.customerImg ? <CatoImg img={sideQuote.customerImg}/> : null;

            return (
                <CustomerSideQuote
                    quote={sideQuote.quote}
                    customerImg={sideQuote.customerImg ? sideQuote.customerImg : null}
                    name={sideQuote.customerName}
                    position={sideQuote.customerPosition}
                />
            )
            // return (
            //     <div className="side-quote">
            //         <div>
            //             <div className="img-wrapper">{img}</div>
            //             <div className="quote-text">{sideQuote.quote}</div>
            //             <div className="source">
            //                 <div className="img">
            //                     {customerImg}
            //                 </div>
            //                 <div className="customer-name">{`${sideQuote.customerName}, ${sideQuote.customerPosition}`}</div>
            //             </div>
            //         </div>
            //     </div>
            // )
        }
        // return bannerImg
    }

    generateClasses = () => {
        const {alignImage, isTopSection, longHeading, offWhite, imageFirst} = this.props;

        let klass = '';

        if(alignImage) {
            klass += ' image-align';
        }

        if(isTopSection) {
            klass += ' top-section';
        }

        if(longHeading) {
            klass += ' long-heading';
        }

        if(offWhite) {
            klass += ' off-white';
        }

        if(imageFirst) {
            klass += ' image-first';
        }

        return klass;
    }

    render() {


        const {includeSideOnMobile, imageFirst, heading, alignImage, isTopSection, anchorID, className, containerRef} = this.props;
        const {isMobile} = this.state;
        const textWrapper = this.generateText();

        const sideContentWrapper = !isMobile || includeSideOnMobile ? this.generateSideContent() : null;
        const headingTag = isTopSection ? <h1>{heading}</h1> : <h2>{heading}</h2>;

        const klass = this.generateClasses();

        return (

            <div className={`info-banner-wrapper${klass} ${className}`} ref={containerRef ? containerRef : null}>
                {anchorID ? <i className="anchor" id={anchorID}/> : null}
                {!imageFirst
                    ?
                    <div>
                        {alignImage && !isMobile && <div className="heading-wrap">{headingTag}</div>}
                        {textWrapper}{sideContentWrapper}
                    </div>
                    :
                    <div>
                        {alignImage && !isMobile && <div className="heading-wrap"><h2>{heading}</h2></div>}
                        {sideContentWrapper}{textWrapper}
                    </div>}
            </div>
        )
    }
}

MidPageInfoBanner.propTypes = {
    bannerImg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    heading: PropTypes.string,
    text: PropTypes.string,
    withCarousel: PropTypes.bool,
    carouselData: PropTypes.arrayOf(PropTypes.object),
    alignImage: PropTypes.bool,
    imageFirst: PropTypes.bool,
    offWhite: PropTypes.bool,
    includeSideOnMobile: PropTypes.bool,
    isTopSection: PropTypes.bool
};

MidPageInfoBanner.defaultProps = {
    withCarousel: true,
    alignImage: false,
    headingTopRow: false
}

export default MidPageInfoBanner;
